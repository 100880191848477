import { SCENES } from "./data/index.js";
import * as Marzipano from "marzipano";

const panoElement = document.getElementById("pano")
export const resetSceneButton = document.getElementById("request-view-reset");

const viewerOpts = {
    controls: {
        mouseViewMode: "drag",
        scrollZoom: true,
    },
};

export const viewer = new Marzipano.Viewer(panoElement, viewerOpts);


if (process.env.NODE_ENV === 'development') {
	// this outputs coordinates of yaw/pitch on click
	document.addEventListener('click', (e) => {
		var view = viewer.view()
		let x = e.clientX
		let y = e.clientY
		console.log(view.screenToCoordinates({ x, y }))
	})
}

// const tourLinkHotspot = SCENES[0].linkHotspots[0]
const tourInfoHotspot = SCENES[0].infoHotspots[0]

// const tourLinkHotspotLookToValues = {
//     yaw: tourLinkHotspot?.yaw,
//     pitch: tourLinkHotspot?.pitch,
//     rotation: tourLinkHotspot?.rotation
// };


const tourLookToInfoHotspot = {
    yaw: tourInfoHotspot?.yaw,
    pitch: tourInfoHotspot?.pitch,
    rotation: tourInfoHotspot?.rotation
};

export const data = {
    scenes: SCENES,

    name: `Pampered Chef - ${process.env.COLLECTION_NAME} VR Kitchen`,
    settings: {
        mouseViewMode: "drag",
        autorotateEnabled: false,
        fullscreenButton: false,
    },
    tourParams: {
        tourSection1: tourLookToInfoHotspot,
        // tourSection2: tourLinkHotspotLookToValues,
    },
};

